import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Card from './Card';
import BaseSlider from './BaseSlider';
import useOnScreen from '../../../../hooks/useOnScreen/useOnScreen';

const MobileView = ({ cards, navigationColor, parentRef }) => {
  const sliderRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const isInViewport = useOnScreen(parentRef);

  return (
    <BaseSlider navigationColor={navigationColor} ref={sliderRef} afterChange={setActiveIndex}>
      {cards.map((card, index) => (
        <Card {...card} key={card.title} isActive={isInViewport && activeIndex === index} />
      ))}
    </BaseSlider>
  );
};

MobileView.propTypes = {
  navigationColor: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      animationFileUrl: PropTypes.string,
      icon: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string
      }),
      shortExplanation: PropTypes.string,
      ctaText: PropTypes.string,
      playlist: PropTypes.shape({
        url: PropTypes.string.isRequired,
        videos: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            cardImage: PropTypes.shape({
              url: PropTypes.string,
              alt: PropTypes.string
            })
          })
        )
      })
    })
  ),
  parentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.shape({}) })
  ])
};

MobileView.defaultProps = {
  cards: [],
  navigationColor: '#397BB9',
  parentRef: null
};

export default MobileView;
