import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Card from './Card';

const useStyles = makeStyles(() => ({
  cardGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    columnGap: '10px'
  }
}));

const DesktopView = ({ cards }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardGrid}>
      {cards.map(card => (
        <div key={card.title}>
          <Card {...card} />
        </div>
      ))}
    </div>
  );
};

DesktopView.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      animationFileUrl: PropTypes.string,
      icon: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string
      }),
      shortExplanation: PropTypes.string,
      ctaText: PropTypes.string,
      playlist: PropTypes.shape({
        url: PropTypes.string.isRequired,
        videos: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            cardImage: PropTypes.shape({
              url: PropTypes.string,
              alt: PropTypes.string
            })
          })
        )
      })
    })
  )
};

DesktopView.defaultProps = {
  cards: []
};

export default DesktopView;
