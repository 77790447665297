import { useEffect, useState } from 'react';

const useOnScreen = ref => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    let observer;
    if (ref?.current && window?.IntersectionObserver) {
      observer = new window.IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      );
      observer.observe(ref.current);
    }
    return () => observer && observer.disconnect();
  }, [ref]);

  return isIntersecting;
};

export default useOnScreen;
