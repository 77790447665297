import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from './Card';

const useStyles = makeStyles(() => ({
  titleButton: {
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    backgroundColor: '#5FA2E0',
    borderRadius: '12px',
    paddingLeft: '20%',
    paddingTop: '26px',
    paddingBottom: '19px',
    textAlign: 'left',
    width: '100%',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: '#4e87bc'
    }
  },
  titleButtonActive: {
    backgroundColor: '#4e87bc'
  }
}));

const TabletView = ({ cards }) => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Box
      display="flex"
      flexDirection="row"
      gridColumnGap="25px"
      justifyContent="space-between"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        gridGap="18px"
        justifyContent="space-between"
        width="50%"
      >
        {cards.map((card, index) => (
          <ButtonBase
            key={`btn_${card.title}`}
            className={`${classes.titleButton} ${
              index === activeIndex ? classes.titleButtonActive : ''
            }`}
            onClick={() => setActiveIndex(index)}
          >
            {card.title}
          </ButtonBase>
        ))}
      </Box>
      <Box width="50%">
        <Card {...cards[activeIndex]} />
      </Box>
    </Box>
  );
};

TabletView.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      animationFileUrl: PropTypes.string,
      icon: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string
      }),
      shortExplanation: PropTypes.string,
      ctaText: PropTypes.string,
      playlist: PropTypes.shape({
        url: PropTypes.string.isRequired,
        videos: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            cardImage: PropTypes.shape({
              url: PropTypes.string,
              alt: PropTypes.string
            })
          })
        )
      })
    })
  )
};

TabletView.defaultProps = {
  cards: []
};

export default TabletView;
