import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Container from '~components/Container';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';
import DesktopView from '~modules/landing-page/LearnTheMethods/DesktopView';
import TabletView from '~modules/landing-page/LearnTheMethods/TabletView';
import MobileView from '~modules/landing-page/LearnTheMethods/MobileView';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: '65px',
    paddingBottom: '135px',
    [theme.breakpoints.down('xs')]: {
      padding: '35px 0px 70px 0px'
    }
  },
  title: {
    color: props => props.text_color,
    textAlign: 'center',
    fontSize: '64px',
    width: '100%',
    fontWeight: 500,
    paddingBottom: '40px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '42px',
      lineHeight: '114%',
      paddingBottom: '25px'
    }
  },
  section: {
    position: 'relative',
    backgroundColor: props => props.background_color
  }
}));

const LearningMethods = ({
  title,
  cards,
  text_color,
  background_color,
  navigation_color,
  bottom_shape_divider
}) => {
  const theme = useTheme();
  const classes = useStyles({ text_color, background_color });
  const containerRef = useRef();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const isTablet = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true }) && !isMobile;
  const isDesktop = !isMobile && !isTablet;

  return (
    <div className={classes.section} ref={containerRef}>
      <Container center className={classes.container}>
        <Typography className={classes.title}>{title}</Typography>
        {/* Render desktop view */}
        {isDesktop && <DesktopView cards={cards} />}
        {/* Render tablet view */}
        {isTablet && <TabletView navigationColor={navigation_color} cards={cards} />}
        {/* Render mobile view */}
        {isMobile && (
          <MobileView navigationColor={navigation_color} cards={cards} parentRef={containerRef} />
        )}
      </Container>
      {bottom_shape_divider?.url && (
        <BottomShapeDivider src={bottom_shape_divider.url} bgColor="transparent" flex />
      )}
    </div>
  );
};

LearningMethods.propTypes = {
  title: PropTypes.string,
  text_color: PropTypes.string,
  background_color: PropTypes.string,
  navigation_color: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      animationFileUrl: PropTypes.string,
      icon: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string
      }),
      shortExplanation: PropTypes.string,
      ctaText: PropTypes.string,
      playlist: PropTypes.shape({
        url: PropTypes.string.isRequired,
        cardImage: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string
        })
      }),
      secondaryPlaylist: PropTypes.shape({
        url: PropTypes.string.isRequired,
        cardImage: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string
        })
      }),
      ternaryPlaylist: PropTypes.shape({
        url: PropTypes.string.isRequired,
        cardImage: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string
        })
      })
    })
  ),
  bottom_shape_divider: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  })
};

LearningMethods.defaultProps = {
  title: 'Some methods you’ll learn',
  cards: [],
  text_color: '#000000',
  background_color: 'transparent',
  navigation_color: '#000000',
  bottom_shape_divider: null
};

export default LearningMethods;
