import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Player } from '@lottiefiles/react-lottie-player';
import Link from '~components/Link';

const useStyles = makeStyles(() => ({
  description: {
    color: '#000000',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    marginBottom: '6px',
    maxWidth: '300px'
  },
  ctaText: {
    color: '#F09F76',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 700,
    textAlign: 'center',
    textDecoration: 'underline'
  }
}));

const Card = props => {
  const { animationFileUrl, ctaText, shortExplanation, playlist } = props;
  const classes = useStyles();
  const [lottieInstance, setLottieInstance] = useState(null);

  useEffect(() => {
    if (lottieInstance && animationFileUrl) {
      lottieInstance.goToAndPlay(0);
    }
  }, [animationFileUrl, lottieInstance]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      borderRadius="11px"
      height="100%"
      width="100%"
      bgcolor="#FCFCFF"
      paddingTop="26px"
      paddingBottom="26px"
    >
      <Box width="fit-content">
        <Player lottieRef={setLottieInstance} src={animationFileUrl} keepLastFrame loop={false} />
      </Box>
      <Typography className={classes.description}>{shortExplanation}</Typography>
      <Link to={playlist?.url} underline="none">
        <Typography className={classes.ctaText}>{ctaText}</Typography>
      </Link>
    </Box>
  );
};

Card.propTypes = {
  animationFileUrl: PropTypes.string,
  icon: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }),
  ctaText: PropTypes.string,
  shortExplanation: PropTypes.string,
  playlist: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    cardImage: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string
    })
  })
};

Card.defaultProps = {
  animationFileUrl: '',
  icon: null,
  shortExplanation: '',
  ctaText: '',
  playlist: null
};

export default Card;
