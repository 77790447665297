import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Player } from '@lottiefiles/react-lottie-player';
import Tooltip from '@material-ui/core/Tooltip';
import PlayButtonImage from '~images/buttons/basic_play_button.svg';
import Link from '~components/Link';
import { ANIMATION_STILL_FRAME, ANIMATION_TYPES } from '../constants';

const CustomToolTip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    maxWidth: '400px',
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: '10px',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
  },
  arrow: {
    color: '#FFFFFF'
  }
}))(Tooltip);

const useStyles = makeStyles(() => ({
  section: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    color: '#000000',
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer'
  },
  animationArea: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: 12,
    marginBottom: '14px',
    cursor: 'pointer',
    '& > *': {
      pointerEvents: 'none'
    }
  },
  avatar: {
    display: 'flex',
    width: '60px',
    height: '60px',
    borderRadius: '99px'
  },
  popupTitle: {
    color: '#222222',
    fontSize: '18px',
    lineHeight: '25px',
    fontWeight: 500
  },
  popupDescription: {
    color: '#6E6D79',
    fontSize: '14px',
    lineHeight: '20px',
    maxWidth: '280px'
  },
  popupVideoThumbnail: {
    maxWidth: '100%',
    borderRadius: '4px',
    cursor: 'pointer'
  },
  popupThumbnailPlayButton: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer'
  }
}));

const Card = props => {
  const {
    title,
    animationFileUrl,
    animationType,
    animationStillFrame,
    icon,
    shortExplanation,
    playlist,
    secondaryPlaylist,
    ternaryPlaylist
  } = props;
  const classes = useStyles();
  const [lottieInstance, setLottieInstance] = useState(null);

  const pauseAnimationAtStillFrame = useCallback(() => {
    if (animationStillFrame === ANIMATION_STILL_FRAME.LAST_FRAME) {
      lottieInstance.goToAndStop(lottieInstance.totalFrames - 1, true);
    } else {
      lottieInstance.goToAndStop(0);
    }
  }, [lottieInstance, animationStillFrame]);

  const animate = useCallback(() => {
    if (
      animationType === ANIMATION_TYPES.TWO_FRAME &&
      animationStillFrame === ANIMATION_STILL_FRAME.FIRST_FRAME
    ) {
      lottieInstance.goToAndStop(lottieInstance.totalFrames - 1, true);
    } else if (animationType === ANIMATION_TYPES.TWO_FRAME) {
      lottieInstance.goToAndStop(0, true);
    } else {
      lottieInstance.goToAndPlay(0);
    }
  }, [lottieInstance, animationType, animationStillFrame]);

  useEffect(() => {
    if (lottieInstance) {
      pauseAnimationAtStillFrame();
    }
  }, [lottieInstance, pauseAnimationAtStillFrame]);

  const onAnimationAreaHover = () => {
    animate();
  };

  const onAnimationAreaHoverOut = () => {
    pauseAnimationAtStillFrame();
  };

  return (
    <div className={classes.section}>
      <div
        className={classes.animationArea}
        onMouseEnter={onAnimationAreaHover}
        onMouseLeave={onAnimationAreaHoverOut}
        onFocus={onAnimationAreaHover}
        onBlur={onAnimationAreaHoverOut}
      >
        <Player lottieRef={setLottieInstance} src={animationFileUrl} keepLastFrame loop={false} />
      </div>
      <CustomToolTip
        arrow
        interactive
        title={
          <Box width="100%" height="100%" padding="15px">
            <Box
              display="flex"
              flexDirection="row"
              gridGap="10px"
              marginBottom="23px"
              marginTop="8px"
            >
              <img src={icon?.url && icon.url} alt={icon?.alt} className={classes.avatar} />
              <Box display="flex" flexDirection="column" gridGap="5px">
                <Typography className={classes.popupTitle}>{title}</Typography>
                <Typography className={classes.popupDescription}>{shortExplanation}</Typography>
              </Box>
            </Box>
            <Grid container direction="row" spacing={1}>
              {[playlist, secondaryPlaylist, ternaryPlaylist]
                .filter(videoPlaylist => videoPlaylist?.id)
                .map(videoPlaylist => (
                  <Grid key={videoPlaylist?.id} item xs={4}>
                    <Link to={videoPlaylist?.url} underline="none">
                      <Box width="100%" position="relative">
                        <img
                          src={PlayButtonImage}
                          alt="play-button"
                          className={classes.popupThumbnailPlayButton}
                        />
                        <img
                          src={videoPlaylist?.cardImage?.url}
                          alt={videoPlaylist?.cardImage?.alt}
                          className={classes.popupVideoThumbnail}
                        />
                      </Box>
                    </Link>
                  </Grid>
                ))}
            </Grid>
          </Box>
        }
      >
        <Typography className={classes.title}>{title}</Typography>
      </CustomToolTip>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  animationFileUrl: PropTypes.string,
  animationType: PropTypes.oneOf([ANIMATION_TYPES.TWO_FRAME, ANIMATION_TYPES.MULTIPLE_FRAMES]),
  animationStillFrame: PropTypes.oneOf([
    ANIMATION_STILL_FRAME.FIRST_FRAME,
    ANIMATION_STILL_FRAME.LAST_FRAME
  ]),
  icon: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }),
  shortExplanation: PropTypes.string,
  playlist: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    cardImage: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string
    })
  }),
  secondaryPlaylist: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    cardImage: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string
    })
  }),
  ternaryPlaylist: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    cardImage: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string
    })
  })
};

Card.defaultProps = {
  animationFileUrl: '',
  animationType: ANIMATION_TYPES.MULTIPLE_FRAMES,
  animationStillFrame: ANIMATION_STILL_FRAME.FIRST_FRAME,
  icon: null,
  shortExplanation: '',
  playlist: null,
  secondaryPlaylist: null,
  ternaryPlaylist: null
};

export default Card;
