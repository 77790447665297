import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Player } from '@lottiefiles/react-lottie-player';
import Link from '~components/Link';
import { ANIMATION_STILL_FRAME } from '../constants';

const useStyles = makeStyles({
  section: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: 12,
    paddingBottom: '30px'
  },
  title: {
    color: '#000000',
    fontSize: '24px',
    lineHeight: '34px',
    fontWeight: 600,
    marginBottom: '6px',
    fontFamily: 'Inter'
  },
  animationArea: {
    width: '100%'
  },
  description: {
    color: '#000000',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginBottom: '6px',
    fontFamily: 'Inter'
  },
  ctaText: {
    color: '#000000',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 700,
    textAlign: 'center',
    textDecoration: 'underline',
    fontFamily: 'Inter'
  }
});

const Card = props => {
  const {
    title,
    animationFileUrl,
    animationStillFrame,
    shortExplanation,
    playlist,
    ctaText,
    isActive
  } = props;
  const classes = useStyles();
  const [lottieInstance, setLottieInstance] = useState(null);

  const pauseAnimationAtStillFrame = useCallback(() => {
    if (animationStillFrame === ANIMATION_STILL_FRAME.LAST_FRAME) {
      lottieInstance.goToAndStop(lottieInstance.totalFrames - 1, true);
    } else {
      lottieInstance.goToAndStop(0);
    }
  }, [lottieInstance, animationStillFrame]);

  useEffect(() => {
    if (lottieInstance && isActive) {
      lottieInstance.goToAndPlay(0);
    } else if (lottieInstance && !isActive) {
      pauseAnimationAtStillFrame();
    }
  }, [isActive, lottieInstance, pauseAnimationAtStillFrame]);

  return (
    <div className={classes.section}>
      <Box display="flex" width="100%" padding="10px 90px">
        <div className={classes.animationArea}>
          <Player lottieRef={setLottieInstance} src={animationFileUrl} keepLastFrame loop={false} />
        </div>
      </Box>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.description}>{shortExplanation}</Typography>
      <Link to={playlist?.url} underline="none">
        <Typography className={classes.ctaText}>{ctaText}</Typography>
      </Link>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  animationFileUrl: PropTypes.string,
  animationStillFrame: PropTypes.oneOf([
    ANIMATION_STILL_FRAME.FIRST_FRAME,
    ANIMATION_STILL_FRAME.LAST_FRAME
  ]),
  icon: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }),
  shortExplanation: PropTypes.string,
  ctaText: PropTypes.string,
  playlist: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    cardImage: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string
    })
  }),
  isActive: PropTypes.bool
};

Card.defaultProps = {
  animationFileUrl: '',
  animationStillFrame: ANIMATION_STILL_FRAME.FIRST_FRAME,
  icon: null,
  shortExplanation: '',
  ctaText: '',
  playlist: null,
  isActive: false
};

export default Card;
